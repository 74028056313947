import useCurrentBusinessIdStore from "@/stores/currentBusinessIdStore";

export default function useBusinessManager() {
  const supabase = useSupabaseClient();
  const selectBusinessByUserId = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .select(
          "*, business_branches(*), coupons(*), business_comments(*), business_changes(*, business_change_comments(*))",
        )
        .eq("business_admin_profile_id", userId);

      if (error) {
        throw new Error(
          `Error al seleccionar el comercio del usuario ${userId}: ${error.message}`,
        );
      }
      // Obtén el store de usuario
      const businessIdStore = useCurrentBusinessIdStore();

      // Asegúrate de que los datos existen y tienen al menos un elemento

      if (data && data.length > 0) {
        // Guarda el businessId en el store
        businessIdStore.setBusinessId(data[0].business_id);
      }
      return data;
    } catch (error) {
      console.error(
        `Error al seleccionar el comercio del usuario ${userId}:`,
        error,
      );
      return null;
    }
  };

  const selectApprovedBusinesses = async (page, pageSize) => {
    try {
      const startIndex = (page - 1) * pageSize;

      const { data, error, count } = await supabase
        .from("businesses")
        .select(
          '*, business_changes("*"), business_statuses("status"), coupons("coupon_id"))',
          {
            count: "exact",
          },
        )
        .in("status_id", [3, 4, 5, 6])
        .range(startIndex, startIndex + pageSize - 1)
        .order("updated_at", { ascending: false });

      if (error) {
        throw new Error(`Error al seleccionar el comercio: ${error.message}`);
      }
      console.log(data);
      return { data, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const selectNotPublishedBusinesses = async (page, pageSize) => {
    try {
      const startIndex = (page - 1) * pageSize;

      const { data, error, count } = await supabase
        .from("businesses")
        .select(
          '*, business_changes("change_status_id"), business_statuses("status"), business_comments(*))',
          {
            count: "exact",
          },
        )
        .in("status_id", [1, 2, 3])
        .range(startIndex, startIndex + pageSize - 1)
        .order("updated_at", { ascending: false });

      if (error) {
        throw new Error(`Error al seleccionar el comercio: ${error.message}`);
      }
      console.log(data);
      return { data, count };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const selectBusiness = async (businessId) => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .select(
          "*, business_branches(*), coupons(*), business_comments(*), business_changes(*, business_change_comments(*))",
        )
        .eq("business_id", businessId);

      if (error) {
        throw new Error(`Error al seleccionar el comercio: ${error.message}`);
      }
      return data;
    } catch (error) {
      console.error(
        `Error al seleccionar el cupón con comercio ${businessId}:`,
        error,
      );
      return null;
    }
  };
  const insertBusiness = async (business) => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .insert([business])
        .select();

      if (error) {
        throw new Error(`Error al insertar el comercio: ${error.message}`);
      }
      return data;
    } catch (error) {
      console.error("Error al insertar el comercio:", error);
      return null;
    }
  };

  const updateBusiness = async (businessId, updates) => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .update(updates)
        .eq("business_id", businessId)
        .select();

      if (error) {
        throw new Error(`Error al actualizar el comercio: ${error.message}`);
      }
      return data;
    } catch (error) {
      console.error(
        `Error al actualizar el comercio con ID ${businessId}:`,
        error,
      );
      return null;
    }
  };

  const selectBusinessForWeb = async (businessId) => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .select("*, business_branches(*), coupons(*, coupon_redeems(*)))")
        .eq("business_id", businessId);

      if (error) {
        throw new Error(`Error al seleccionar el comercio: ${error.message}`);
      }

      data[0].coupons = data[0].coupons
        .filter((coupon) => {
          const startDate = new Date(coupon.start_date);
          const endDate = new Date(coupon.end_date);

          const now = new Date();

          return coupon.status_id === 5 && now >= startDate && now <= endDate;
        })
        .map((coupon) => {
          return { ...coupon, business_logo: data[0].business_logo };
        });

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const selectFeaturedBusinesses = async () => {
    try {
      const { data, error } = await supabase
        .from("businesses")
        .select(
          "business_id, business_logo, business_display_name, coupons(status_id)",
        )
        .eq("status_id", 4)
        .eq("is_featured", true);

      if (error) {
        throw new Error(`Error al seleccionar el comercio: ${error.message}`);
      }

      const filteredBusinesses = data.filter((business) => {
        return business.coupons.some((coupon) => {
          return coupon.status_id === 5;
        });
      });

      return filteredBusinesses;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    selectBusiness,
    selectApprovedBusinesses,
    selectNotPublishedBusinesses,
    selectBusinessByUserId,
    insertBusiness,
    updateBusiness,
    selectBusinessForWeb,
    selectFeaturedBusinesses,
  };
}
